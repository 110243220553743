import {
	updateMyStoreAndNearbyStoresView,
	initializeMakeMyStoreHandler,
	displayStoreDirections
} from 'features/bopus/utilities';
import { getDefaultStoreTemplate, getNearbyStoreTemplate } from 'features/bopus/desktop';
import { getNearByStores } from 'features/bopus/api';
import { utagLinkHeader } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';

const trackEvent = utagLinkHeader();

function trackStoreDetails () {

	trackEvent({
		eventAction: 'click-Stores',
		eventLabel: 'Store Details'
	});
	setGenericEvent({ event_name: 'navtop_stores_details_click' });

}

function trackStoreDefault () {

	trackEvent({
		eventAction: 'click-Stores',
		eventLabel: 'Store Details'
	});
	setGenericEvent({ event_name: 'navtop_stores_setdefault_click' });

}

function renderMyDefaultStore (store) {

	const myStoreWrapper = document.querySelector('.myStoreWrapper');
	if (myStoreWrapper) {

		myStoreWrapper.classList.add('loaded');
		myStoreWrapper.innerHTML = getDefaultStoreTemplate({ store, secureSiteURL: window.lp.globals.secureSiteURL });
		myStoreWrapper.classList.remove('hidden');
		myStoreWrapper.querySelectorAll('.storeDetailLink').forEach((link) => {

			link.addEventListener('click', trackStoreDetails, { once: true });

		});
		myStoreWrapper.querySelectorAll('.makeThisMyStore').forEach((link) => {

			link.addEventListener('click', trackStoreDefault, { once: true });

		});

	}

}

function renderNearbyStores (stores) {

	const storesNearMe = document.querySelector('.storesNearMe');
	const { isKioskWithoutStoreModifier, isUserBopusEligible } = window.lp.globals;
	stores = stores.filter((s) => !s.IsDefaultStore);

	if (storesNearMe && stores.length > 0) {

		storesNearMe.classList.add('loaded');
		storesNearMe.innerHTML = getNearbyStoreTemplate({ stores, isKioskWithoutStoreModifier, isUserBopusEligible });
		storesNearMe.classList.remove('hidden');
		storesNearMe.querySelectorAll('.storeDetailLink').forEach((link) => {

			link.addEventListener('click', trackStoreDetails, { once: true });

		});
		storesNearMe.querySelectorAll('.makeThisMyStore').forEach((link) => {

			link.addEventListener('click', trackStoreDefault, { once: true });

		});

	}

}

function trackZipSearch (zipcode) {

	trackEvent({
		eventAction: 'click-Store',
		eventLabel: zipcode
	});
	setGenericEvent({ event_name: 'navtop_stores_zipcode_click' });

}

function init () {

	const $bopusSubmenu = $('#bopusSubmenu');

	if (window.lp.globals.isUserBopusEligible && $bopusSubmenu.length > 0) {

		let timeout;
		let xhr;

		$bopusSubmenu.on('over focus', () => {

			timeout = setTimeout(() => {

				if (!xhr) {

					xhr = getNearByStores().then((response) => {

						updateMyStoreAndNearbyStoresView({
							stores: response.ApiResult,
							renderMyDefaultStore,
							renderNearbyStores
						});

					});

				}

			}, 0);

		});

		$bopusSubmenu.on('out', () => {

			clearTimeout(timeout);
			$('.locateStoreInput').blur();
			if (xhr) {

				// TODO: fetch does not support abort at this time
				// Use AbortController API (https://developers.google.com/web/updates/2017/09/abortable-fetch) when it becomes widely supported

				// Abort fetch request here

				xhr = null;

			}

		});

		const $findStoreBtn = $('#hdrStoresDropdown .findStore');

		$findStoreBtn.on('click', (e, triggered = false) => {

			const $storeInput = $('.locateStoreInput');
			const zip = $.trim($storeInput.val());

			if (!triggered && zip !== '') {

				trackZipSearch(zip);

			}

			e.preventDefault();

			if (!Number.isNaN(zip) && zip.length === 5) {

				window.location.href = `/stores/?zipcode=${zip}`;

			} else {

				$storeInput.focus();

			}

		});

		$('.locateStoreInput').click((e) => {

			e.preventDefault();
			e.stopPropagation();

		});

		$('.locateStoreInput')
			.inputFilter()
			.on('keydown', (e) => {

				if (e.which === 13) {

					trackZipSearch(e.currentTarget.value.trim());
					$findStoreBtn.trigger('click', true);
					e.preventDefault();

				}

			});

		$('.storeLocationsLink').on('click', (e) => {

			e.preventDefault();

		});

		initializeMakeMyStoreHandler({ renderMyDefaultStore, renderNearbyStores });

	}

	$('.storeLocationsButton').on('click', (e) => {

		window.location.href = '/stores/';

	});

	$('#mapDirections').on('click', (e) => {

		e.preventDefault();
		displayStoreDirections();

	});

	$('#mapImage').on('keydown', (e) => {

		// If the user presses the "Enter" key on the keyboard
		if (e.key === 'Enter') {

			document.getElementById('mapDirections')?.click();

		}

	});

}

export default init;
