import { schemes } from '../schemes';

import getGenericHorizontalContent from './getGenericHorizontalContent';
import getSortPageContent from './getSortPageContent';
import getGenericContent from './getGenericContent';
import getMoreYouMayLikeContent from './getMoreYouMayLikeContent';
import getSimiliarNoSearchContent from './getSimiliarNoSearchContent';
import getMoreLikeThisContent from './getMoreLikeThisContent';
import getLastCategoryContent from './getLastCategoryContent';
import getRecentlyViewedHomePageContent from './getRecentlyViewedHomePageContent';
import getMoreLikeThisCartPageContent from './getMoreLikeThisCartPageContent';
import getRelatedItemContent from './getRelatedItemContent';
import getGlobalRecentlyViewedFooterContent from './getGlobalRecentlyViewedFooterContent';
import getOrderConfirmationExpiredContent from './getOrderConfirmationExpiredContent';
import getSortMoreYouMayLikeContent from './getSortMoreYouMayLikeContent';
import getCartPageMoreYouMayLikeContent from './getCartPageMoreYouMayLikeContent';
import getError404MoreYouMayLikeContent from './getError404MoreYouMayLikeContent';
import getHomeTrendingProductsContent from './getHomeTrendingProductsContent';
import config from './config';

const { isMoreLikeThis } = window.lp.pageData;
const { page_name, page_type } = window?.utag_data || '';

const isOrderConfirmationExpiredPage = !!(window.location.pathname.toLowerCase() === '/cart/confirmationexpired/');

let homeWidgetItemCountsForDisplay;
const getHomeWidgetItemCountsForDisplay = (results) => {

	if (typeof homeWidgetItemCountsForDisplay === 'undefined') {

		const maxSlots = config.HOME_WIDGET_ITEMS_MAX_SLOTS;
		const justForYouWidget = results.filter((r) => r.Scheme === 'lastCategory_rr');
		const recentlyViewedWidget = results.filter((r) => r.Scheme === 'GF_homepage_rr');
		const totalRecentlyViewedItems = recentlyViewedWidget[0]?.Products.length || 0;
		const totalJustForYouItems = justForYouWidget[0]?.Products.length || 0;
		let recentlyViewedItems = Math.min(totalRecentlyViewedItems, maxSlots);
		const remainingSlots = maxSlots - recentlyViewedItems || 1;
		const justForYouItems = Math.min(remainingSlots, totalJustForYouItems, maxSlots);
		const homeTrendingWidget = results.filter((r) => r.Scheme === 'home_rr');
		const totalHomeTrendingItems = homeTrendingWidget[0]?.Products.length || 0;
		const homeTrendingItems = Math.min(totalHomeTrendingItems, maxSlots);

		if (recentlyViewedItems === maxSlots) {

			recentlyViewedItems -= justForYouItems;

		}

		homeWidgetItemCountsForDisplay = {
			recentlyViewedItems,
			justForYouItems,
			homeTrendingItems
		};

	}

	return homeWidgetItemCountsForDisplay;

};

const getAdditionalSetting = (result, results) => {

	switch (page_type) {

		case 'product':
		case 'confirmation':
		case 'landing': {

			const setting = {
				imageSize: 270,
				withTitle: true,
				withRatings: true,
				dynamicImageSize: true
			};
			const landingPageUrls = [
				'/chandeliers/',
				'/kitchen-lighting/',
				'/ceiling-lighting/',
				'/lamps/',
				'/outdoor/',
				'/fans/',
				'/furniture/',
				'/home-decor/',
				'/table-lamps/'
			];
			if (page_type !== 'landing' || landingPageUrls.includes(window.location.pathname)) {

				return setting;

			}
			break;

		}

		case 'home': {

			const homeWidgetItemCounts = getHomeWidgetItemCountsForDisplay(results);

			// Recently Viewed widget
			if (result.Scheme === 'GF_homepage_rr') {

				return {
					maxItemsToDisplay: homeWidgetItemCounts.recentlyViewedItems
				};

			}

			// Home Trending widget
			if (result.Scheme === 'home_rr') {

				return {
					maxItemsToDisplay: homeWidgetItemCounts.homeTrendingItems
				};

			}

			// Just For You widget
			if (result.Scheme === 'lastCategory_rr') {

				return {
					maxItemsToDisplay: homeWidgetItemCounts.justForYouItems
				};

			}

			break;

		}

		default:
			break;

	}

	return null;

};

function getLayoutContentFromScheme(scheme, result, results) {

	const additionalSetting = getAdditionalSetting(result, results);

	switch (scheme.toLowerCase()) {

		case schemes.GENERIC_HORIZONTAL:
			return getGenericHorizontalContent(result);
		case schemes.SORT_PAGE:
			return getSortMoreYouMayLikeContent(result, {
				imageSize: 270,
				withPrice: true,
				withTitle: true,
				withRatings: true
			});
		case schemes.CATEGORY_LANDING:
			if (additionalSetting) {

				return getMoreYouMayLikeContent(result, {
					itemsPerRow: 5,
					imageSize: 160,
					withPrice: true,
					moreLikeThis: false,
					...(additionalSetting || {})
				});

			}
		case schemes.CATEGORY_LANDING_02:
			if (additionalSetting) {

				return getMoreYouMayLikeContent(result, {
					itemsPerRow: 5,
					imageSize: 160,
					withPrice: true,
					moreLikeThis: false,
					...(additionalSetting || {})
				});

			}
			return getSortPageContent(result, { scheme });
		case schemes.HOME:
			if (page_type === 'error_404' || page_name === '404 Error - Page Not Found | Lamps Plus') {

				return getError404MoreYouMayLikeContent(result, {
					imageSize: 270,
					withPrice: true,
					withTitle: true,
					withRatings: true
				});

			}
			if (page_type === 'home') {
				if (result?.Scheme.toLowerCase() === 'home_rr') {
					return getHomeTrendingProductsContent(result, {
						itemsPerRow: 5,
						imageSize: 270,
						withPrice: true,
						withTitle: true,
						withRatings: true
					});
				}
				return getMoreYouMayLikeContent(result, {
					itemsPerRow: 5,
					imageSize: 160,
					withPrice: true,
					moreLikeThis: false
				});
			}
		case schemes.SIMILAR:
			return getGenericContent(result, {
				itemsPerRow: 8,
				imageSize: 100,
				withPrice: true,
				moreLikeThis: false
			});
		case schemes.GF_HOME:
			return getRecentlyViewedHomePageContent(result, {
				imageSize: 180,
				withPrice: true,
				showTitle: true,
				...(additionalSetting || {})
			});
		case schemes.CART:
			return getCartPageMoreYouMayLikeContent(result, {
				itemsPerRow: 10,
				imageSize: 290,
				withPrice: true,
				moreLikeThis: true,
				showRatings: true,
				showTitle: true
			});
		case schemes.RECENTLY_VIEWED_ITEMS:
			return getGlobalRecentlyViewedFooterContent(result, {
				itemsPerRow: 10,
				imageSize: 290,
				withPrice: true,
				moreLikeThis: true,
				showRatings: true,
				showTitle: true
			});
		case schemes.LAST_CATEGORY_PAGE:
		case schemes.RECENTLY_VIEWED_PAGE:
			// Handled separately as React widgets
			return;
		case schemes.LAST_CATEGORY:
			return getLastCategoryContent(result, {
				imageSize: 180,
				withPrice: true,
				showTitle: true,
				...(additionalSetting || {})
			});
		case schemes.PRODUCT:
			return getMoreYouMayLikeContent(
				result,
				{
					itemsPerRow: 5,
					imageSize: 160,
					withPrice: true,
					moreLikeThis: false,
					...(additionalSetting || {})
				},
				1
			);
		case schemes.SHIPPING:
			if (isMoreLikeThis && !additionalSetting) {

				return getMoreLikeThisContent(result);

			}
			return getMoreYouMayLikeContent(result, {
				itemsPerRow: 5,
				imageSize: 160,
				withPrice: true,
				moreLikeThis: false,
				...(additionalSetting || {})
			});
		case schemes.ORDER_CONFIRMATION: {

			if (isOrderConfirmationExpiredPage) {

				return getOrderConfirmationExpiredContent(result, {
					itemsPerRow: 5,
					imageSize: 270,
					withPrice: true,
					moreLikeThis: false,
					showRatings: true,
					showTitle: true
				});

			}
			return getGenericContent(result, {
				itemsPerRow: 5,
				imageSize: 270,
				withPrice: true,
				moreLikeThis: false
			});

		}
		case schemes.ORDER_STATUS:
			return getGenericContent(result, {
				imageSize: 160,
				withPrice: true,
				doNotAppend: true,
				moreLikeThis: false
			});
		case schemes.SIMILAR_2:
			return getSimiliarNoSearchContent(result, {
				classname: 'sortResult',
				moreLikeThis: true,
				outerClass: true
			});
		case schemes.NO_SEARCH:
		case schemes.NO_SEARCH_SKU:
			return getMoreYouMayLikeContent(
				result,
				{
					imageSize: 270,
					itemsPerRow: 5,
					dynamicImageSize: true,
					withPrice: true,
					moreLikeThis: false,
					withTitle: true,
					withRatings: true
				},
				1
			);
		case schemes.MORE_LIKE_THIS: {

			return getMoreLikeThisContent(result);

		}
		case schemes.RELATED: {

			return getRelatedItemContent(result);

		}
		case schemes.WISHLIST: {

			return getGenericContent(result, {
				itemsPerRow: 5,
				imageSize: 160,
				withPrice: true,
				moreLikeThis: false
			});

		}
		case schemes.SIMILARATC: {

			let dataStr = getMoreLikeThisCartPageContent(result, {
				imageSize: 160,
				withPrice: true,
				moreLikeThis: false
			});
			if (result.Products.length < 4) {

				// Here, 4 is a max product count on MLT modal.
				const extraDivs = 4 - result.Products.length;
				for (let i = 0; i < extraDivs; i++) {

					dataStr += '<div class="moreYouMayLikeProduct extraDiv"></div>';

				}

			}

			return dataStr;

		}
		default:
			return '';

	}

}

export default getLayoutContentFromScheme;
