import { apiMessages } from 'ui/messages';
import { delegateEvent } from 'utilities/dom';
import { utagLinkHeader } from 'features/tealium';
import { getStoresByGeoLocation } from 'features/bopus/mobile';

import { updateMyStore, getNearByStores } from '../api';

import updateMyStoreAndNearbyStoresView from './updateMyStoreAndNearbyStoresView';

const trackHeaderEvent = utagLinkHeader();

function setNewStore (target) {

	const prevStore = document.querySelector('.makeThisMyStore.myStore');
	const pointerClasses = [ 'myStore', 'noPointerEvents' ];
	if (prevStore) {

		// IE doesnt allow multiple class arguments in classList.add/remove
		pointerClasses.forEach((currentClass) => prevStore.classList.remove(currentClass));
		prevStore.innerHTML = 'Make this my store'; // remove previous store
		prevStore.removeAttribute('role');
		prevStore.removeAttribute('tabindex');

	}

	pointerClasses.forEach((currentClass) => target.classList.add(currentClass));
	// eslint-disable-next-line no-param-reassign
	target.innerHTML = '<span>My Store</span>'; // make as current store
	target.setAttribute('role', 'status');
	target.setAttribute('tabindex', '-1');
	target.blur();

}

function setNewStoreMobile (target) {

	const prevStore = document.querySelector('.defaultStore');
	if (prevStore) {

		const store = prevStore.closest('.storeDetails');
		const btnWrapper = store.querySelector('.storeMakeMyStoreWrapper');
		if (btnWrapper) {

			btnWrapper.innerHTML = `<button type="button" class="makeMyStore anchorLink storeLink" data-store="${prevStore.getAttribute(
				'data-store'
			)}">Make This My Store</button>`;

		}
		prevStore.parentNode.removeChild(prevStore);

	}

	// eslint-disable-next-line no-param-reassign
	const myStore = target.closest('.storeDetails');
	const headingWrapper = myStore.querySelector('.storeHeading');
	if (headingWrapper) {

		headingWrapper.innerHTML += `<div class="defaultStore" data-store="${target.getAttribute(
			'data-store'
		)}"><span class="lpIcon-home"></span>&nbsp;My Store</div>`;

	}
	target.parentNode.removeChild(target);

}

function updateMyStoreHandler ({
	isCalloutBtn,
	loadingClass,
	target,
	type = '',
	renderMyDefaultStore,
	renderNearbyStores,
	useGeoLocation,
	forMobile
}) {

	if (isCalloutBtn) {

		target.classList.remove(...loadingClass);

	}

	switch (type) {

		case 'list': {

			if (useGeoLocation) {

				getStoresByGeoLocation({ renderMyDefaultStore, renderNearbyStores });

			} else {

				getNearByStores()
					.then((response) => {

						updateMyStoreAndNearbyStoresView({
							stores: response.ApiResult,
							renderMyDefaultStore,
							renderNearbyStores
						});

					})
					.catch(() => {

						throw new Error(apiMessages.error);

					});

			}
			break;

		}
		case 'toggle':
		default: {

			if (forMobile) setNewStoreMobile(target);
			else setNewStore(target);
			break;

		}

	}

}

function makeThisMyStoreHandler ({
	event, renderMyDefaultStore, renderNearbyStores, useGeoLocation, forMobile
}) {

	trackHeaderEvent({ eventAction: 'click-Stores', eventLabel: 'Make-My-Store' });
	event.preventDefault();
	const { target } = event;

	if (target.classList.contains('myStore') || target.parentNode.classList.contains('myStore')) {

		return;

	}

	const { store, type } = target.dataset;
	const isCalloutBtn = target.classList.contains('calloutBtn');
	const loadingClass = [ 'loading', 'disabled' ];

	if (isCalloutBtn) {

		target.classList.add(...loadingClass);

	}

	updateMyStore(store)
		.then(() => updateMyStoreHandler({
			target,
			isCalloutBtn,
			type,
			loadingClass,
			renderMyDefaultStore,
			renderNearbyStores,
			useGeoLocation,
			forMobile
		}))
		.catch(() => {

			if (isCalloutBtn) {

				target.classList.remove(...loadingClass);

			}

			throw new Error(apiMessages.error);

		});

}

function initializeMakeMyStoreHandler ({ renderMyDefaultStore, renderNearbyStores, useGeoLocation }) {

	// Some Make This My Store links are generated dynamically in JS, so use event delegation
	// Make This My Store has different parent or ancestor for different pages on mobile and desktop
	// Some pages have multiple areas containing 2 of these selectors, so we have to get all containers
	const containers = document.querySelectorAll(
		'#bopusSubmenu, .storesNearby, .storeResultContainer, .storeTopContainer, .page'
	);

	containers.forEach((container) => {

		container.addEventListener(
			'click',
			delegateEvent(
				(event) => makeThisMyStoreHandler({
					event,
					renderMyDefaultStore,
					renderNearbyStores,
					useGeoLocation,
					forMobile: true
				}),
				'.makeMyStore'
			)
		);
		container.addEventListener(
			'click',
			delegateEvent(
				(event) => makeThisMyStoreHandler({
					event,
					renderMyDefaultStore,
					renderNearbyStores,
					useGeoLocation,
					forMobile: false
				}),
				'.makeThisMyStore'
			)
		);

	});

}

export default initializeMakeMyStoreHandler;
