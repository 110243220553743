import { schemes } from '../schemes';

import initRelatedItemsGlider from './initRelatedItemsGlider';
import initMymlGlider from './initMymlGlider';
import initRecentlyViewedGlider from './initRecentlyViewedGlider';
import initPrevNextTabObserver from './initPrevNextTabObserver';
import config from './config';

let recentlyViewedGliderInstance = null;

function hydrateRecentlyViewedContainer($container, content) {

	$container.html(content);

}

function renderLayoutContent(scheme, content) {

	const { isMoreLikeThis } = window.lp.pageData;
	switch (scheme.toLowerCase()) {

		case schemes.GENERIC_HORIZONTAL: {

			const $certonaItems = $('#certonaItems');
			$certonaItems.html(content);
			$('.certonaScrollable').scrollable({
				touch: $('#certonaItems li').index() >= 1
			});
			$certonaItems.find('.next.disabled').off('click');
			break;

		}
		case schemes.SORT_PAGE:
			if (document.querySelector('#pdMoreYouMayLike')) {

				initMymlGlider({
					selector: '#moreYouMayLikeContainer',
					arrowsSelector: '#pdMoreYouMayLike',
					content
				});
				break;

			}
		case schemes.CATEGORY_LANDING:
			if (document.querySelector('.moreYouMayLike2')) {

				initMymlGlider({
					selector: '#certonaItems',
					arrowsSelector: '.moreYouMayLike2',
					content
				});
				break;

			}
		case schemes.ORDER_STATUS:
		case schemes.SIMILAR_2:
		case schemes.WISHLIST: {

			$('#certonaItems').html(content);
			break;

		}
		case schemes.NO_SEARCH:
		case schemes.NO_SEARCH_SKU:
			initMymlGlider({
				selector: '#moreYouMayLikeContainer',
				arrowsSelector: '#pdMoreYouMayLike',
				content
			});
			break;
		case schemes.HOME: {
			if (document.querySelector('.homeTrendingProductsContainer')) {
				initMymlGlider({
					selector: '.homeTrendingProductsContainer',
					content
				});

			} else {
				initMymlGlider({
					selector: '#moreYouMayLikeContainer',
					arrowsSelector: '#pdMoreYouMayLike',
					content
				});
			}
			break;

		}
		case schemes.CATEGORY_LANDING_02: {

			if (document.querySelector('.trendingCertonaItems2')) {

				initMymlGlider({
					selector: '#trendingCertonaItems',
					arrowsSelector: '.trendingCertonaItems2',
					content
				});

			}
			break;

		}
		case schemes.SIMILAR: {

			$('#similarDesignsContainer').html(content);
			$('html, body').animate({ scrollTop: 0 }, 500);
			break;

		}
		case schemes.LAST_CATEGORY: {

			const $justForYouContainer = $('#justForYouWidgetContainer');

			if ($justForYouContainer.length) {

				$justForYouContainer.html(content);

				const justForYouCtr = $justForYouContainer.find('.certonaWidgetContainer__item').length;
				const recentlyViewedCtr = $justForYouContainer.prev().find('.certonaWidgetContainer__item').length;

				if (justForYouCtr === 0) {

					$justForYouContainer.addClass('hidden');

				}

				if (justForYouCtr + recentlyViewedCtr < config.HOME_WIDGET_ITEMS_MAX_SLOTS) {

					$justForYouContainer.parent().addClass('widgetContainer--notFull');

				}

			}

			break;

		}
		case schemes.GF_HOME: {

			const $recentlyViewedWidgetContainer = $('#recentlyViewedWidgetContainer');
			if ($recentlyViewedWidgetContainer.length) {

				hydrateRecentlyViewedContainer($recentlyViewedWidgetContainer, content);

			}
			break;

		}

		case schemes.RECENTLY_VIEWED_ITEMS: {

			if (recentlyViewedGliderInstance) {

				recentlyViewedGliderInstance.destroy();
				recentlyViewedGliderInstance = null;

			}

			recentlyViewedGliderInstance = initRecentlyViewedGlider(content);

			break;

		}
		case schemes.LAST_CATEGORY_PAGE:
		case schemes.RECENTLY_VIEWED_PAGE: {

			// Handled separately as React widgets
			break;

		}
		case schemes.PRODUCT: {

			initMymlGlider({
				selector: '#moreYouMayLikeContainer',
				arrowsSelector: '#pdMoreYouMayLike',
				content
			});
			initPrevNextTabObserver('#pdMoreYouMayLike');
			break;

		}
		case schemes.SHIPPING: {

			if (isMoreLikeThis) {

				const $mainContainer = $('.jsMainContainer');
				// remove skeleton UI
				$mainContainer.find('.sortSkeletonItem').remove();

				$mainContainer.append(content);

			} else {

				initMymlGlider({
					selector: '#moreYouMayLikeContainer',
					arrowsSelector: '#pdMoreYouMayLike',
					content
				});
				initPrevNextTabObserver('#pdMoreYouMayLike');

			}

			break;

		}
		case schemes.CART: {

			initMymlGlider({
				selector: '#cartMoreYouMayLikeContainer',
				arrowsSelector: '.cartMoreYouMayLikeWrapper',
				content
			});
			break;

		}
		case schemes.ORDER_CONFIRMATION: {

			initMymlGlider({
				selector: '#cartMoreYouMayLikeContainer',
				arrowsSelector: '#cartSuggestedProductsContainer',
				content
			});
			break;

		}

		case schemes.RELATED: {

			$('#pdRelItmsContainer .glider-container').html(content);
			$('#pnlRelatedItems').find('.pdTabLoader').fadeOut(500);

			// Initialize Glider
			initRelatedItemsGlider();

			break;

		}
		case schemes.MORE_LIKE_THIS: {

			const $mainContainer = $('.jsMainContainer');
			// remove skeleton UI
			$mainContainer.find('.sortSkeletonItem').remove();

			$mainContainer.append(content);
			break;

		}
		case schemes.SIMILARATC: {

			$('#modalMoreYouMayLikeModal').html(content);
			break;

		}
		default:
			break;

	}

}

export default renderLayoutContent;
