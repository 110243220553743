function getStoreTemplate (store, hasBopusLink) {

	const bopusLink = `<button
							type="button"
							class="makeThisMyStore storeLink anchorLink"
							data-store="${store.StoreNumber}"
							data-type="list"
						>Make this my store</button>`;

	return `
		<li>
			<div class="storeNearMeBlock">
				<div class="storeName"><a href="${store.DetailUrl}">${store.StoreName}</a></div>
				<div class="storeInfo">${store.StreetAddress}</div>
				<div class="storeInfo">${store.City}, ${store.State} ${store.ZipCode}</div>
				${store.Phone ? `<div class="storePhone">Phone: ${store.Phone}</div>` : ''}
				<div class="storeLinks">
					<a class="storeDetailLink storeLink"
						href="${store.DetailUrl}">Details</a>${hasBopusLink ? `<span></span>${bopusLink}` : ''}
				</div>
			</div>
		</li>
	`;

}

function getNearbyStoreTemplate ({ stores, isKioskWithoutStoreModifier, isUserBopusEligible }) {

	const storesHTML = stores
		.map((store) => getStoreTemplate(store, !isKioskWithoutStoreModifier && isUserBopusEligible))
		.join('');
	return `
		<div class="submenuHeader">Stores near me</div>
		<ul class="submenuList unstyledList">
			${storesHTML}
		</ul>
	`;

}

export default getNearbyStoreTemplate;
