function handleButtonClassChange (button) {

	if (button.classList.contains('disabled')) {

		button.setAttribute('tabindex', -1);

	} else {

		button.removeAttribute('tabindex');

	}

}
function initPrevNextTabObserver (containerSelector) {

	const container = document.querySelector(containerSelector);
	if (!container) {

		return;

	}

	const prevButton = container.querySelector('.glider-prev');
	const nextButton = container.querySelector('.glider-next');

	const handlePrevClassChange = () => handleButtonClassChange(prevButton);
	handlePrevClassChange();

	const prevObserver = new MutationObserver(handlePrevClassChange);
	prevObserver.observe(prevButton, {
		attributes: true,
		attributeFilter: [ 'class' ]
	});

	const handleNextClassChange = () => handleButtonClassChange(nextButton);
	handleNextClassChange();

	const nextObserver = new MutationObserver(handleNextClassChange);
	nextObserver.observe(nextButton, {
		attributes: true,
		attributeFilter: [ 'class' ]
	});

}

export default initPrevNextTabObserver;
