function getDefaultStoreTemplate ({ store, secureSiteURL }) {

	const {
		City,
		DetailUrl,
		EndTime,
		IsOpenNow,
		IsTemporarilyClosed,
		State,
		StoreName,
		StreetAddress,
		ZipCode,
		Phone
	} = store;

	return `
		<div class="submenuHeader">My Store</div>
		<div class="submenuList">
			<div class="storeName">
			<a class="storeNameLink" href="${secureSiteURL}${DetailUrl}">${StoreName}</a>
				</div>
				${IsOpenNow && !IsTemporarilyClosed ? `<div class="storeOpenUntil">Open until ${EndTime}</div>` : ''}
				<div class="storeInfo">
				<div class="storeAddressLine">${StreetAddress}</div>
				<div class="storeCityStateZip">${City}, ${State} ${ZipCode}</div>
				${Phone ? `<div class="storePhone">Phone: ${Phone}</div>` : ''}
			</div>
			<div class="storeLinks">
				<a class="storeDetailLink storeLink" href="${secureSiteURL}${DetailUrl}">Details</a>
			</div>
		</div>
	`;

}

export default getDefaultStoreTemplate;
